<template>
	<div class="my-profile">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back route="MyProfile" />
		</div><!-- header -->
		<div class="header-image grey"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="LoginIcon"
				subtitle="My Profile"
				title="Login Details"
				colour="profile profile-cyan"
			/>
            <div v-if="apple" class="section-gap text-block">
                <p class="no-space"><strong>Your profile is connected to Apple</strong></p>
                <p>Good news, you don’t need to worry about changing your email address or password, as your account is linked to your Apple profile.</p>
            </div>
            <div v-else-if="google" class="section-gap text-block">
                <p class="no-space"><strong>Your profile is connected to Google</strong></p>
                <p>Good news, you don’t need to worry about changing your email address or password, as your account is linked to your Google profile.</p>
            </div>
			<div v-else-if="facebook" class="section-gap text-block">
				<p class="no-space"><strong>Your profile is connected to Facebook</strong></p>
				<p>Good news, you don’t need to worry about changing your email address or password, as your account is linked to your Facebook profile.</p>
			</div>
			<form v-else class="form" @submit.prevent="">
				<div class="field" :class="{ 'field-error': $v.form.email.$error || errorStyling == true }">
					<label for="email-input" class="label">Email Address</label>
					<div class="control">
						<input id="email-input" class="input" type="email" placeholder="Enter your email address" v-model="form.email">
					</div>
				</div>
				<div class="field field-gap field-icon disabled" :class="{ 'field-error':$v.form.password.$error || errorStyling == true }">
					<label for="password-input" class="label">Password</label>
					<div class="control">
						<input id="password-input" class="input" type="password" v-model="form.password" >
						<LockIcon style="width: 20px; height: 21px;" class="icon icon-lock" />
					</div>
				</div>
				<div class="field error" v-if="errorMessage">
					<span class="field-error-message">Please make sure all fields are filled in.</span>
				</div>
				<div class="flex-bottom">
					<Button @click.native="$store.commit('UPDATE_PASSWORD_TOGGLE');" icon="KeyIcon" class="password-btn none-bottom btn btn-med btn-grey btn-tick btn-square btn-icon btn-icon-right" text="Change My Password"></Button>
					<Button @click.native="updateLoginDetails" icon="LoadingIcon" class="none-bottom btn btn-med btn-green btn-tick btn-square btn-icon btn-icon-right" text="Update Login Details"></Button>
					<a class="text-link" @click="archiveAccount">Disable my account &raquo;</a>
				</div><!-- flex bottom -->
			</form>
		</div>
        <UpdateProfileOverlay />
		<UpdatePasswordOverlay />
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Button from '@/components/Button.vue';
	import NewsCard from '@/components/NewsCard.vue';
	import CardRow from '@/components/CardRow.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import UpdatePasswordOverlay from '@/components/UpdatePasswordOverlay.vue';
	import { required } from 'vuelidate/lib/validators';
	import LockIcon from '@/assets/lock.svg';
    import UserService from '@/services/UserService';
    import UpdateProfileOverlay from '@/components/UpdateProfileOverlay.vue';
	export default {
		name: 'MyProfileLoginDetails',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			LockIcon,
			Button,
			UpdatePasswordOverlay,
            UpdateProfileOverlay
		},
		data () {
	        return {
                facebook: false,
                google: false,
                apple: false,
	        	form: {
	        		email: '',
	        		password: '**********'
	        	}
	        };
	    },
	    validations () {
	    	return {
	    		form: {
	            	email: {
						required
					},
					password: {
						required
					}
	           	}
	    	};
	    },
	    methods: {
            getUser () {
                UserService.getUser().then((response) => {
                    this.facebook = response.data.facebook;
                    this.google = response.data.google;
                    this.apple = response.data.apple;
                    this.form.email = response.data.email;
                });
            },
            updateLoginDetails () {
                var self = this;
                this.$v.form.$touch();
                if (!this.$v.form.$error) {
                    try {
                        UserService.updateUser({
                            email: this.form.email
                        }).then(() => {
                            this.$store.commit('UPDATE_PROFILE_TOGGLE');
                        });
                    } catch (error) {
                        console.log(error);
                        this.errorMessage = true;
                        this.errorStyling = true;
                    }
                }
            },
            archiveAccount () {
                if (confirm('Are you sure you want to disable your account?')) {
                    UserService.archiveAccount().then(() => {
                        this.$router.push('/');
                    });
                }
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
            this.getUser();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.password-btn {
		margin-bottom: 50px;
	}
	.text-link {
		color: $mid;
	}
	.icon-lock {
		width: 18px;
    	height: auto;
		g, path {
			stroke-width: 4;
			stroke: $light!important;
		}
	}
	.section-title {
		&.red {
			color: #C83254;
		}
	}
	form {
		margin-top: 30px;
		margin-bottom: 100px;
		.field {
			margin-bottom: 25px;
		}
	}
</style>
