<template>
<div ref="modalUpdatePassword" class="phone-modal phone-modal-port-search with-nav" style="bottom: -618px;" :class="{active: isUpdatePasswordModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<KeyIcon style="width: 25px; height: auto;" class="icon key-icon" />
			<span v-if="title">{{ title }}</span>
			<span v-else>Change My Password</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closeUpdatePasswordModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<form style="padding-top: 10px;" class="form" @submit.prevent="">
		<div class="field field-icon" :class="{ 'field-error':$v.form.currentPassword.$error || errorStyling == true }">
			<label for="password-input" class="label">Current Password</label>
			<div class="control">
				<input id="password-input" class="input" type="password" placeholder="Enter your current password" v-model="form.currentPassword" >
				<LockIcon style="width: 20px; height: 21px;" class="icon icon-key" />
			</div>
			<router-link :to="{ name: 'Home' }" class="field-sub field-sub-red">Forgotten your password?</router-link>
		</div>
		<div class="field field-icon" :class="{ 'field-error':$v.form.newPassword.$error || errorStyling == true }">
			<label for="password-input" class="label">New Password</label>
			<div class="control">
				<input id="password-input" class="input" type="password" placeholder="Enter your new password" v-model="form.newPassword" >
				<KeyIcon style="width: 20px; height: 21px;" class="icon icon-key" />
			</div>
		</div>
		<div class="field field-gap field-icon" :class="{ 'field-error':$v.form.newPasswordRepeat.$error || errorStyling == true }">
			<label for="password-input-repeat" class="label">Repeat New Password</label>
			<div class="control">
				<input id="password-input-repeat" class="input" type="password" placeholder="Retype your new password" v-model="form.newPasswordRepeat" >
				<KeyIcon style="width: 20px; height: 21px;" class="icon icon-key" />
			</div>
		</div>
		<div class="field error" v-if="errorMessage">
			<span class="field-error-message">Incorrect email address or password.</span>
		</div>
		<Button @click.native="updatePassword" icon="LoadingIcon" class="btn-med btn-green btn-tick btn-icon btn-icon-right btn-arrow" text="Update Your Password"></Button>
		<a @click="closeUpdatePasswordModal" class="text-link">I don't want to change my password &raquo;</a>
	</form>
</div><!-- modal -->
</template>

<script>
import KeyIcon from '@/assets/key.svg';
import LockIcon from '@/assets/lock.svg';
import GreyCloseIcon from '@/assets/close-grey.svg';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/Button.vue';
import UserService from '@/services/UserService';
export default {
name: 'UpdatePasswordOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	components: {
		GreyCloseIcon,
		KeyIcon,
		LockIcon,
		Button
	},
	data () {
		return {
			form: {
				currentPassword: null,
				newPassword: null,
				newPasswordRepeat: null
			}
		};
	},
	validations () {
    	return {
    		form: {
            	currentPassword: {
					required
				},
				newPassword: {
					required
				},
				newPasswordRepeat: {
					required
				}
           	}
    	};
   },
    computed: {
        isUpdatePasswordModalOpen () {
            return this.$store.state.isUpdatePasswordModalOpen;
        }
    },
	methods: {
        closeUpdatePasswordModal () {
        	this.$store.commit('UPDATE_PASSWORD_TOGGLE');
        },
        updatePassword () {
            UserService.updatePassword({
                current_password: this.form.currentPassword,
                password: this.form.newPassword,
                password_confirmation: this.form.newPasswordRepeat
            }).then(() => {
                this.$store.commit('UPDATE_PASSWORD_TOGGLE');
                this.$store.commit('UPDATE_PROFILE_TOGGLE');
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.text-link {
		color: $mid;
	}
	form {
		.field {
			margin-bottom: 25px;
		}
		.btn {
			margin-top: 35px;
		}
	}
	.section-title-wrap .section-title {
		color: $grey;
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $cyan_dark;
				}
			}
			span {
				color: $cyan_dark;
			}
		}
	}
	.card-wrap {
		.card-block-purple {
			margin-bottom: 15px;
		}
	}
</style>
